import React from 'react'

function CompanyName() {

    const CompanyName = 'Web Design Expressions'

    return (
        <>
            <span aria-label="Web Design Expressions Custom Web Design Services" title='Web Design Expressions | Custom Web Design and Website Redesign Services'>{CompanyName}</span>
        </>
    )
}

export default CompanyName