import React, { useRef, useEffect, useState } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { toPng } from 'html-to-image';

const QR = () => {
    const qrRef = useRef(null);
    const [companyName, setCompanyName] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [qrValue, setQrValue] = useState('');

    const handleGenerateQR = () => {
        setQrValue(`Company name: ${companyName}\nURL: ${websiteUrl}`);
    };

    const downloadQR = () => {
        if (qrRef.current === null) return;
        toPng(qrRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.download = 'qr-code-with-text.png';
                link.href = dataUrl;
                link.click();
            })
            .catch((err) => console.error(err));
    };

    return (
        <div>
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Oregano:wght@400&display=swap');
            </style>

            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="border p-2 mr-2"
                />
                <input
                    type="text"
                    placeholder="Website URL"
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                    className="border p-2 mr-2"
                />
                <button onClick={handleGenerateQR} className="bg-blue-500 text-white px-4 py-2">
                    Create QR Code
                </button>
            </div>

            <div ref={qrRef} style={{ background: 'transparent', display: qrValue ? 'block' : 'none' }}>
                <QRCodeCanvas
                    value={qrValue}
                    size={512}
                    bgColor="transparent"
                    fgColor="#C13E39"
                    includeMargin
                />
            </div>

            {qrValue && (
                <button onClick={downloadQR} className="mt-4 bg-green-500 text-white px-4 py-2">
                    Download QR Code
                </button>
            )}
        </div>
    );
};

export default QR;
