import React from 'react';
import { Divider } from '@mui/material'
import SocialMedia from './socialMedia';

const PageHeader = ({ pageTitle }) => {
    return (
        <header className='mx-16'>
            <div className='lg:flex pt-8'>
                <div className='lg:w-9/12 lg:my-0 my-2'><h1 className='lg:text-5xl text-xl lg:Company-Main-Font lg:font-normal font-bold'>{pageTitle}</h1></div>
                <div className='lg:w-3/12 lg:my-0  my-2'>
                    <div className='lg:float-right lg:text-right'><SocialMedia /></div>
                </div>
            </div>
            <div className='py-4'><Divider /></div>
        </header>
    );
};


export default PageHeader