import React, { useState } from "react";
import { jsPDF } from "jspdf";
import { TextField, Button, Box } from "@mui/material";
import PageHeader from '../Component/PageHeader';

const SEOFactory = () => {
    const [pages, setPages] = useState([
        { pageTitle: "", url: "", metaDescription: "" },
    ]);

    const titleLimit = 55;
    const descriptionLimit = 155;

    const addPage = () => {
        setPages([...pages, { pageTitle: "", url: "", metaDescription: "" }]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedPages = [...pages];
        updatedPages[index][field] = value;
        setPages(updatedPages);
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();

        let y = 10; // Starting Y position
        const lineHeight = 10; // Space between lines

        pages.forEach((page, i) => {
            const title = page.pageTitle || `Page ${i + 1}`;
            const url = page.url || "No URL provided";
            const metaDescription = page.metaDescription || "No description provided";

            // Page Title
            doc.setFontSize(12);
            doc.text(`Page Title: ${title}`, 10, y);
            y += lineHeight;

            // Title Characters Count
            doc.text(`Characters count: ${title.length}/${titleLimit}`, 10, y);
            y += lineHeight;

            // URL
            doc.text(`URL: ${url}`, 10, y);
            y += lineHeight;

            // Meta Description Label and Content on One Line
            doc.text(`Description: ${metaDescription}`, 10, y, { maxWidth: 155 });
            y += lineHeight;

            // Meta Description Characters Count
            doc.text(
                `Characters count: ${metaDescription.length}/${descriptionLimit}`,
                10,
                y
            );
            // Add a line after the character count
            y += 5;
            doc.line(10, y, 200, y); // Draw a line at the current y position
            y += 5; // Adding space after the line

            y += lineHeight * 1.5; // Add some extra space between sections
        });







        doc.save("SEOManagement.pdf");
    };

    return (
        <div>
            <div><PageHeader pageTitle="SEO Management" /></div>


            <div className="mx-16">

                <Box>


                    {pages.map((page, index) => (
                        <Box key={index}>
                            <div>{index + 1}</div>

                            <TextField
                                label="Page Title"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={page.pageTitle}
                                onChange={(e) =>
                                    e.target.value.length <= titleLimit &&
                                    handleInputChange(index, "pageTitle", e.target.value)
                                }
                                helperText={`${page.pageTitle.length}/${titleLimit} characters`}
                            />
                            <TextField
                                label="URL"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                value={page.url}
                                onChange={(e) => handleInputChange(index, "url", e.target.value)}
                            />
                            <TextField
                                label="Meta Description"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                multiline
                                rows={4}
                                value={page.metaDescription}
                                onChange={(e) =>
                                    e.target.value.length <= descriptionLimit &&
                                    handleInputChange(index, "metaDescription", e.target.value)
                                }
                                helperText={`${page.metaDescription.length}/${descriptionLimit} characters`}
                            />
                        </Box>
                    ))}

                    <Button variant="outlined" onClick={addPage} sx={{ mt: 2 }}>
                        Add Page
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePDFDownload}
                        sx={{ mt: 2, ml: 2 }}
                    >
                        Download as PDF
                    </Button>
                </Box>
            </div>
        </div>
    );
};

export default SEOFactory;
