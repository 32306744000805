import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import accounts from './Accounts.json';
import PageHeader from '../Component/PageHeader';

// Helper function to format the expiration date
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Format date as MM/DD/YYYY (can adjust as needed)
};

const AllAccounts = () => {
    const history = useHistory();

    // Function to handle sorting by date automatically (closest expiration date first)
    const sortAccounts = (accounts) => {
        return accounts.sort((a, b) => {
            const dateA = new Date(a.expirationDate);
            const dateB = new Date(b.expirationDate);
            return dateA - dateB; // Ascending order (closest date first)
        });
    };

    // Calculate the total value of all accounts, checking if domainPrice and hostingPrice are valid
    const totalValue = accounts.reduce((total, account) => {
        const domainPrice = parseFloat(account.domainPrice);
        const hostingPrice = parseFloat(account.hostingPrice);

        // Only add valid prices to the total
        const accountTotal = (isNaN(domainPrice) ? 0 : domainPrice) + (isNaN(hostingPrice) ? 0 : hostingPrice);

        return total + accountTotal; // Add the valid account total to the running total
    }, 0);

    // Sorted accounts by expiration date
    const sortedAccounts = sortAccounts(accounts);

    return (
        <div>
            <div><PageHeader pageTitle="Domain & Hosting" /></div>


            <div className="mx-16">
                <div className="my-4">
                    {/* Show the total value */}
                    <div className='text-2xl font-bold text-right'>Total Value: ${totalValue.toFixed(2)}</div>
                </div>
                <div className="grid grid-cols-1 gap-4">
                    {/* Loop through each account and display it in a Card */}
                    {sortedAccounts.map((account) => (
                        <Card
                            key={account.id}
                            sx={{
                                boxShadow: 3,
                                borderRadius: 2,
                                padding: 0,
                                backgroundColor: 'transparent',
                                backdropFilter: 'blur(10px)',
                                border: '1px solid rgba(0, 0, 0, 0.2)',
                                cursor: 'pointer',
                            }}
                            onClick={() => history.push(`/Office/AccountDetails/${account.id}`)}
                        >
                            <CardContent>
                                <div className="font-bold">{account.accountName}</div>
                                <div>Expiration: {formatDate(account.expirationDate)}</div>
                            </CardContent>
                        </Card>
                    ))}
                </div>


            </div>
        </div>
    );
};

export default AllAccounts;
