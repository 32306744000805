import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import accounts from './Accounts.json'; // Assuming you're importing the accounts from a local JSON file

const AccountDetails = () => {
    const { id } = useParams(); // Get the dynamic 'id' from the URL
    const [account, setAccount] = useState(null);

    useEffect(() => {
        // Find the account based on the id from the URL
        const foundAccount = accounts.find(account => account.id === parseInt(id));
        setAccount(foundAccount);
    }, [id]);

    if (!account) {
        return <div>Account not found!</div>;
    }

    return (
        <div className='mx-16'>

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}><div className='font-bold'>{account.accountName}</div></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Expiration Date:</TableCell>
                                <TableCell>{account.expirationDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Domain:</TableCell>
                                <TableCell>{account.domain}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Domain Price:</TableCell>
                                <TableCell>${account.domainPrice}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Hosting Price:</TableCell>
                                <TableCell>${account.hostingPrice}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}><div>Details:</div></TableCell>

                            </TableRow>
                            <TableRow>
                                <TableCell>Registrar:</TableCell>
                                <TableCell>{account.details.registrar}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Hosting Provider:</TableCell>
                                <TableCell>{account.details.hostingProvider}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Notes:</TableCell>
                                <TableCell>{account.details.notes}</TableCell>
                            </TableRow>
                        </TableBody>
                    </TableHead>
                </Table>
            </TableContainer>



        </div>
    );
};

export default AccountDetails;
