// /src/Office/Main.js

import React from "react";
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReceiptIcon from '@mui/icons-material/Receipt';
import DomainIcon from '@mui/icons-material/Domain';
import StorageIcon from '@mui/icons-material/Storage';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';

const theme = createTheme({
    palette: {
        primary: {
            main: "#DB324D",
        },
        secondary: {
            main: "#1B7C88",
        },
    },
});

const Main = () => {
    return (
        <div className="mx-16">
            <h1 className="text-3xl font-bold mb-4">Dashboard</h1>


            <div className="lg:flex gap-5 my-5">
                <div className="lg:lg:w-1/4 text-center p-1">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/Invoicing"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<ReceiptIcon />}
                        >

                            <b>Invoices</b>
                        </Button>
                    </ThemeProvider>

                </div>
                <div className="lg:w-1/4 text-center p-1">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/AllAccounts"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<DomainIcon />}
                        >

                            <b>Domains & Hosting</b>
                        </Button>
                    </ThemeProvider>

                </div>

                <div className="lg:w-1/4 text-center p-1">
                    <ThemeProvider theme={theme}>
                        <Button
                            variant="contained"
                            color="primary"
                            component="a"
                            href="/Office/SEOFactory"
                            title=''
                            fullWidth
                            size="large"
                            endIcon={<ScreenSearchDesktopIcon />}
                        >

                            <b>SEO Factory</b>
                        </Button>
                    </ThemeProvider>
                </div>
            </div>


        </div>
    );
};

export default Main;
