import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PageHeader from './Component/PageHeader';
import CompanyName from './Component/CompanyName';
import NeedRedesign from './Images/WebsiteRedesign.webp'
import RedesignServices from './Component/RedesignServices';
import WhyChoose from './Component/WhyChoose';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Action from './Component/Action';



const RedesignProcess = [
    {
        id: 1,
        Process: 'Initial Consultation and Website Audit',
        Description: 'We kick off with an in-depth consultation to understand your business objectives and analyze your current website. This includes a comprehensive audit focusing on site structure, mobile responsiveness, user experience (UX), SEO, and overall performance. Our goal is to identify key areas for improvement and create a strategic plan for your website redesign.'
    },
    {
        id: 2,
        Process: 'Custom Design and Wireframing',
        Description: 'We craft wireframes that map out the structure and layout of your redesigned website. This step ensures your vision aligns with the final design while prioritizing a user-friendly experience and modern aesthetics. Our designs emphasize affordability without compromising on quality or functionality.'
    },
    {
        id: 3,
        Process: 'Website Development and Testing',
        Description: 'Our skilled developers bring the design to life, creating a fast, secure, and fully responsive website. We rigorously test the site on multiple browsers and devices to ensure optimal performance, speed, and seamless navigation for your audience.'
    },
    {
        id: 4,
        Process: 'SEO and Content Enhancement',
        Description: 'To maximize your online visibility, we optimize your content for search engines by integrating relevant keywords like "affordable website redesign" and "custom web design." From enhancing metadata to improving internal linking, we ensure your site is primed to rank higher in search results while providing valuable content for your target audience.'
    },
    {
        id: 5,
        Process: 'Website Launch and Ongoing Support',
        Description: 'After a thorough review, we launch your newly redesigned website. But our work doesn’t stop there—we offer ongoing support to address updates, resolve bugs, and make adjustments as needed to keep your site performing at its best.'
    },

]


// Custom styled components
const TransparentAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const TransparentAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const TransparentAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1f2937' : 'transparent',
    color: theme.palette.mode === 'dark' ? '#ffffff' : 'inherit',
}));

const CustomExpandMoreIcon = styled(ExpandMoreIcon)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#F13030' : '#F13030',
}));

const Redesign = () => {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <div><PageHeader pageTitle="Affordable Website Redesign Services" /></div>

            <Helmet>
                <title data-react-helmet="true">Affordable Website Redesign Services | Custom Website Redesign</title>
                <meta data-react-helmet="true" name="description" content="Need a website refresh? Web Design Expressions specializes in custom website redesign services that improve performance, usability, and SEO optimization. Boost your brand today!" />
                <meta data-react-helmet="true" name="keywords" content="custom website redesign, affordable website redesign services, website refresh, professional website redesign" />
                <meta data-react-helmet="true" property="og:title" content="Custom Website Redesign | Affordable Website Redesign Services" />
                <meta data-react-helmet="true" property="og:type" content="website" />
                <meta data-react-helmet="true" property="og:url" content="https://webdesignexpressions.net/CustomWebDesign" />
                <meta data-react-helmet="true" property="og:image" content="https://firebasestorage.googleapis.com/v0/b/rely-3d69e.firebasestorage.app/o/OGRedesign.jpg?alt=media&token=4b99b093-e637-47ce-8fd1-60317335467d" />
                <meta data-react-helmet="true" property="og:description" content="Upgrade your online presence with a custom website redesign. Improve aesthetics, usability, and SEO with Web Design Expressions." />
            </Helmet>

            <div className='lg:float-right lg:w-7/12 lg:ml-10 mb-10'>
                <img className='mx-auto' alt='affordable website redesign services' src={NeedRedesign} title='Affordable Website Redesign Services for Startups and Entrepreneurs' />
            </div>
            <div className='mx-16'>
                <div className='text-2xl font-bold pb-4'><h2>Your Website Might Need a Redesign</h2></div>
                <div>In today's fast-paced digital world, your website is often the first point of contact between your business and potential customers. If your site feels outdated, doesn't perform well on mobile devices, or lacks SEO optimization, it may be time for a redesign. An updated, user-friendly website can boost your brand's visibility, engage customers, and help drive conversions.</div>
                <div className='py-4'>At <CompanyName />, we specialize in redesigning websites with a focus on visual appeal, usability, and modern functionality. Our team will work with you to refresh your site's design while maintaining its unique identity, ensuring it resonates with your target audience and meets your business goals.</div>
                <div>Moreover, we understand that a successful website redesign is not just about aesthetics—it's about improving overall performance. From faster load times to optimized content for SEO, we ensure that your site is not only visually appealing but also designed to rank higher in search engine results. This holistic approach helps you reach a wider audience and keep visitors engaged, ultimately turning them into loyal customers.</div>

            </div>

            <div className='mx-16 clear-both'><Action /></div>



            <div className='mx-16'>
                <div className='text-2xl font-bold pb-5'><h2>What We Offer in Our Website Redesign Services</h2></div>
                <div>Our comprehensive website redesign process is tailored to your needs, covering everything from visual design to technical enhancements and SEO improvements.</div>
            </div>


            <div className='mx-16 mt-10'>
                <RedesignServices />
            </div>

            <div className='mx-16 mt-10'>
                <div className='text-2xl font-bold pb-5'><h2>Our Affordable Website Redesign Process</h2></div>
                <div>At <CompanyName />, our website redesign process is tailored to deliver a custom, responsive, and SEO-optimized website that meets your business goals. Here's how we transform your website into a high-performing digital asset:</div>


                {RedesignProcess.map((Process, index) => (
                    <TransparentAccordion className='my-5'
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                    >
                        <TransparentAccordionSummary
                            expandIcon={<CustomExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <div className="font-bold"><h2><span className='text-5xl text-[#C13E39]'>{Process.id}. </span>{Process.Process}</h2></div>
                        </TransparentAccordionSummary>
                        <TransparentAccordionDetails>
                            {Process.Description}
                        </TransparentAccordionDetails>
                    </TransparentAccordion>
                ))}
            </div>


            <div className=''><WhyChoose /></div>
        </div>
    )
}

export default Redesign