import React from 'react'
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import CompanyName from './CompanyName';
import SocialMedia from './socialMedia';
import '../App.css';
// import FooterNavigation from './FooterNavigation';
// import HiddenRecaptcha from './reCaptcha';




const today = new Date();


function Footer() {

    return (
        <div className='mt-5 clear-both'>
            {/* <div><FooterNavigation /></div> */}
            <Divider />


            <div className='lg:flex'>
                <div className='lg:w-6/12'>
                    <div className='pt-4'><Link href='/Home' style={{ color: 'inherit', textDecoration: 'none', fontWeight: 'bold' }} exact="true">&copy; {today.getFullYear()} - <CompanyName /></Link></div>
                    <div className='pb-4'><Link href='/TermsofUse' title='Terms & Conditions' className='print:hidden' style={{ color: 'inherit', textDecoration: 'none' }} exact="true">Terms & conditions</Link></div>

                </div>

                <div className='lg:w-6/12'>
                    <div className='lg:float-right lg:text-right'>
                        <SocialMedia />
                    </div>
                </div>
            </div>





            {/* <HiddenRecaptcha /> */}
        </div>
    )
}

export default Footer