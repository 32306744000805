// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAAPfk-3Tl5CR8OcJLFwSKWvjJQX-wHKU0",
    authDomain: "rely-3d69e.firebaseapp.com",
    projectId: "rely-3d69e",
    storageBucket: "rely-3d69e.appspot.com",
    messagingSenderId: "816673670149",
    appId: "1:816673670149:web:5d1978f51c58b34dfec86c",
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
export const auth = getAuth(app);
export { storage };
